import React from "react"
import Layout from "../components/Layout"
import { css } from "@emotion/core"

import useMovies from '../hooks/use-movies';
import MediaStub from "../components/MediaStub";

export default function Movies() {
  const movies = useMovies();

  return(
    <>  
      <Layout>
        <h1>MOVIES I'VE WATCHED</h1>
        <div css={css`
        display: flex;
        flex-wrap: wrap;
        
        gap: 1em;
        justify-content: space-between;
        > article {
          flex: 1;
          padding: 1rem;
          background: #6B87A8;
          color: white;
          border-radius: 5px;
          h3 {
            color: white;
          }
          
        }
      `}>
          {movies.map(movie => (
            <MediaStub media={movie} dateText="Watched" />
          ))}
        </div>
      </Layout>
    </>
  )
}
